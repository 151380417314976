<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Alterar indicação</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid" ref="form">
          <div class="mb-4" @keypress.enter.prevent="submit()">
            <custom-auto-complete
              v-model="form.indicator"
              ref="indicator"
              label="Usuário de indicação"
              itemText="name"
              itemValue="id"
              :items="users"
              @update:search-input="handleSearchUser($event)"
            />
          </div>
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      interval: null,
      users: [],
      form: {
        indicator: "",
      },
    };
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  beforeMount() {
    this.getUsers();
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "setUserParentComission",
          userID: this.$route.params.id,
          newUserId: this.form.indicator,
        };

        await request(payload).then(() => {
          this.displayAlert("Indicador alterado com sucesso");

          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async getUsers(search = "") {
      try {
        const payload = {
          method: "listUsers",
          nameFilter: !!search ? search : "",
        };

        await request(payload).then((res) => {
          this.users = res.users.map((el) => {
            return {
              id: el.id,
              name: el.name,
            };
          });
        });
      } catch (err) {
        console.log(err);
      }
    },

    handleSearchUser(event) {
      if (this.interval) clearInterval(this.interval);

      this.interval = setTimeout(() => {
        this.getUsers(event);
      }, 500);
    },

    open() {
      this.dialog = true;

      setTimeout(() => {
        this.startForm();
      });
    },

    startForm() {
      if (Object.keys(this.user.quemIndicou).length > 0) {
        this.$refs.indicator.handleInput(this.user.quemIndicou.id);
      }
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    displayAlert,
  },
};
</script>

<style></style>
