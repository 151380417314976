<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Alterar nível de comissão</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid" ref="form">
          <div class="mb-4" @keypress.enter.prevent="submit()">
            <custom-select
              v-model="form.level"
              ref="level"
              label="Nível de comissão"
              :items="levels"
            />
          </div>
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="text-none mr-2" plain @click="close()">Cancelar</v-btn>

          <v-btn color="primary" class="text-none" depressed @click="submit()">
            Confirmar
          </v-btn>
        </div>
      </div>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      levels: [
        {
          value: "0",
          label: "Redefinir",
        },
      ],
      form: {
        level: "",
      },
    };
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  beforeMount() {
    this.getLevels();
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "updateNivelComissao",
          idNivelComissao: Number(this.form.level),
          userID: this.$route.params.id,
        };

        await request(payload).then((res) => {
          this.displayAlert(res.message);

          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async getLevels() {
      try {
        const payload = {
          method: "getComissoesNivelAdmin",
        };

        await request(payload).then((res) => {
          this.levels = [
            ...this.levels,
            ...res.niveisComissao.map((el) => ({
              value: String(el.id),
              label: el.nome,
            })),
          ];
        });
      } catch (err) {
        console.log(err);
      }
    },

    open() {
      this.dialog = true;

      setTimeout(() => {
        this.startForm();
      });
    },

    startForm() {
      this.$refs.level.handleInput(this.user.nivelDeComissoes.id);
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    displayAlert,
  },
};
</script>

<style></style>
