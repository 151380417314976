<template>
  <div>
    <v-row>
      <v-col cols="12" sm="10" md="8" xl="6">
        <v-card class="shadow mb-4">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> Permitir comissionamento </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-switch
                v-model="allowCommission"
                small
                inset
                @change="updateAffiliatePerm()"
              />
            </v-list-item-action>
          </v-list-item>
        </v-card>

        <v-card class="shadow mb-4">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> Editar indicação </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                color="primary"
                text
                small
                @click="handleChangeIndication()"
              >
                alterar
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>

        <v-card class="shadow mb-4">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> Alterar nível de comissão </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="primary" text small @click="handleChangeLevel()">
                alterar
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>

        <v-card class="shadow mb-4">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> Adicionar comissão manual </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="primary" text small @click="handleAddCommission()">
                Adicionar
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <ChangeIndication
      ref="changeIndication"
      :user="user"
      @success="handleRefreshUser()"
    />

    <ChangeLevel
      ref="changeLevel"
      :user="user"
      @success="handleRefreshUser()"
    />

    <AddCommission ref="addCommission" @success="handleRefreshUser()" />

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { request } from "@/services";
import ChangeIndication from "@/components/user/commissioning/ChangeIndication.vue";
import ChangeLevel from "@/components/user/commissioning/ChangeLevel.vue";
import AddCommission from "@/components/user/commissioning/AddCommission.vue";

export default {
  data() {
    return {
      loading: false,
      allowCommission: false,
    };
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    ChangeIndication,
    ChangeLevel,
    AddCommission,
  },

  beforeMount() {
    this.allowCommission = this.user.ativaComissao === "1" ? true : false;
  },

  methods: {
    async updateAffiliatePerm() {
      try {
        this.loading = true;

        const payload = {
          method: "setAtivaComissao",
          userID: this.user.id,
          ativaComissaoValue: !!this.enableAffiliates ? "1" : "0",
        };

        await request(payload).then(() => {
          this.handleRefreshUser();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleChangeIndication() {
      this.$refs.changeIndication.open();
    },

    handleChangeLevel() {
      this.$refs.changeLevel.open();
    },

    handleAddCommission() {
      this.$refs.addCommission.open();
    },

    handleRefreshUser() {
      this.$emit("refresh");
    },
  },
};
</script>

<style></style>
